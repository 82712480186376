<!--
 * @Descripttion: 
 * @version: 
 * @Author: 张磊（zhanglei1204@jd.com）
 * @Date: 2021-05-09 22:27:04
 * @LastEditors: 张磊
 * @LastEditTime: 2021-05-31 17:13:12
 * @FilePath: /官网迭代/src/views/park/index.vue
-->

<template>
  <div class="park_page">
    <div class="sports_banner">
      <img src="../../assets/img/declaration/banner.png" />
    </div>
    <div class="stepbar">
      <ul>
        <li :class="{ active: isActiveIndex > 0 }">
          <i class="circle"></i>手机号验证<img
            src="../../assets/img/declaration/arr.png"
            class="arr"
          />
        </li>
        <li :class="{ active: isActiveIndex > 1 }">
          <i class="circle"></i>信息确认<img
            src="../../assets/img/declaration/arr.png"
            class="arr"
          />
        </li>
        <li :class="{ active: isActiveIndex > 2 }">
          <i class="circle"></i>确认申报流程<img
            src="../../assets/img/declaration/arr.png"
            class="arr"
          />
        </li>
        <li :class="{ active: isActiveIndex > 3 }">
          <i class="circle"></i>完善信息<img
            src="../../assets/img/declaration/arr.png"
            class="arr"
          />
        </li>
        <li :class="{ active: isActiveIndex > 4 }">
          <i class="circle"></i>完善发票信息<img
            src="../../assets/img/declaration/arr.png"
            class="arr"
          />
        </li>
        <li :class="{ active: isActiveIndex > 5 }">
          <i class="circle"></i>预览协议，确认申报
        </li>
      </ul>
    </div>
    <!-- 手机号验证 -->
    <div class="step1" v-show="isActiveIndex === 1">
      <div class="steptit">手机号验证</div>
      <div class="checkphone w1200">
        <form class="layui-form">
          <div class="layui-form-item">
            <label class="layui-form-label">注册的手机号：</label>
            <div class="layui-input-block">
              <input
                type="text"
                @keyup="inputChanges"
                name="phone"
                required
                maxlength="11"
                lay-verify="required"
                placeholder="请输入标题"
                autocomplete="off"
                class="layui-input"
                v-model="phone"
                readonly="true"
              />
            </div>
          </div>
          <div class="layui-form-item">
            <label class="layui-form-label">短信验证码：</label>
            <div class="layui-input-block">
              <input
                type="text"
                @keyup="inputChange"
                maxlength="4"
                v-model="code"
                required
                lay-verify="required"
                placeholder="请输入短信验证码"
                autocomplete="off"
                class="layui-input"
              />
              <div class="btnblue" id="captcha" @click="hanlePhoneCode">
                {{ codeTime ? `${codeTime}s` : "发送验证码" }}
              </div>
            </div>
          </div>

          <div class="btnsub tab" @click="submitPhone">提交</div>
        </form>
      </div>
    </div>
    <!-- 信息确认 -->
    <div class="step2 w1200" v-show="isActiveIndex === 2">
      <div style="line-height: 0.52rem">
        <p class="MsoNormal infoConfirm-tit">
          <b><span>一、</span> <span>教育理念</span></b
          ><b></b>
        </p>
        <p class="MsoNormal infoConfirm-cont">
          幼儿园需全面贯彻落实全国教育大会精神，牢固树立健康第一的教育理念，严格按照《全国足球特色幼儿园游戏活动指南》要求，积极开
          展幼儿足球相关工作，帮助幼儿在体育锻炼中享受乐趣、增强体质、健全人格、锤炼意志。
        </p>
        <p class="MsoNormal infoConfirm-tit">
          <b><span>二、</span> <span>教育要求</span></b
          ><b></b>
        </p>
        <p class="MsoNormal infoConfirm-cont">
          入选幼儿园需严格按照全国校足办和中国足球发展基金会要求，严格按照全国足球特色幼儿园系统平台课程所提供的教学体系要求，全面
          开展相关教学活动，包括但不限于:幼儿园基础信息管理体系，幼儿足球游戏课程体系，幼儿足球游戏测试体系，幼儿足球运动评估体系，幼儿
          足球教师资培训体系，小小运动会，幼儿足球全国综合量化评估体系等;按时上传相关数据材料，并确保真实有效。
        </p>
        <p class="MsoNormal infoConfirm-tit">
          <b><span>三、</span> <span>考核要求</span></b
          ><b></b>
        </p>
        <p class="MsoNormal infoConfirm-cont">
          入选幼儿园将接受由中国足球发展基金会提供的 2
          万元定向资助金。此资助金是为示范园开展试点教学活动的费用，实行专款专用。
        </p>
        <p class="MsoNormal infoConfirm-tit">
          <b><span>四、</span> <span>资格要求</span></b
          ><b></b>
        </p>
      </div>
      <div class="invoice_block">
        <div class="check_item">
          1、园所需具备签约主体资格,并在系统平台完成实名认证
          <span class="finished">{{status ? '已达成' : '未达成'}}</span>
        </div>
        <div class="check_item">
          2、园所需开具发票等有效票据，或当地教育局/财政局等单位可为其代收代付代开票
        </div>

        <div class="invoice_type">
          <div class="invoice_title">请选择票据类型：</div>
          <div class="bill_list">
            <div class="right_item tab bill_item" 
              :class="{
                  checked: invoiceCurrentType == '普票' && !invoiceTypeInput.label,
                }"
            @click="handleInvoiceType(1, '普票')">
              1 增值税普通发票
              <!-- <span
                class="check"
                
              ></span> -->
            </div>
            <div class="right_item tab bill_item" :class="{
                  checked: invoiceCurrentType == '专票' && !invoiceTypeInput.label,
                }" @click="handleInvoiceType(2, '专票')">
              2 增值税专用发票
              <!-- <span
                class="check"
                :class="{
                  active: invoiceType.type == 2 && !invoiceTypeInput.label,
                }"
              ></span> -->
            </div>
            <div class="right_item tab bill_item" :class="{
                  checked: invoiceCurrentType == '1、公益事业接收捐赠统一收据',
                }" @click="handleInvoiceType(3, '1、公益事业接收捐赠统一收据')">
              3 公益事业接收捐赠统一收据
              <!-- <span
                class="check"
                :class="{
                  active: invoiceType.type == 2 && !invoiceTypeInput.label,
                }"
              ></span> -->
            </div>
          </div>
          <div class="bill_list">
            <div class="right_item tab bill_item" :class="{
                  checked: invoiceCurrentType == '2、公益事业捐赠统一票据（电子）',
                }" @click="handleInvoiceType(3, '2、公益事业捐赠统一票据（电子）')">
              4 公益事业捐赠统一票据(电子)
              <!-- <span
                class="check"
                :class="{
                  active: invoiceType.type == 2 && !invoiceTypeInput.label,
                }"
              ></span> -->
            </div>
            <div class="right_item tab bill_item" :class="{
                  checked: invoiceCurrentType == '3、通用机打发票',
                }" @click="handleInvoiceType(3, '3、通用机打发票')">
              5 通用机打发票
              <!-- <span
                class="check"
                :class="{
                  active: invoiceType.type == 2 && !invoiceTypeInput.label,
                }"
              ></span> -->
            </div>
            <div class="right_item tab bill_item" :class="{
                  checked: invoiceCurrentType == '4、行政事业单位资金往来结算票据',
                }" @click="handleInvoiceType(3, '4、行政事业单位资金往来结算票据')">
              6 行政事业单位资金往来结算票据
              <!-- <span
                class="check"
                :class="{
                  active: invoiceType.type == 2 && !invoiceTypeInput.label,
                }"
              ></span> -->
            </div>
          </div>
          <div class="bill_list">
            <div class="right_item tab bill_item" :class="{
                  checked: invoiceCurrentType == '5、专用收款收据',
                }" @click="handleInvoiceType(3, '5、专用收款收据')">
              7 专用收款收据
              <!-- <span
                class="check"
                :class="{
                  active: invoiceType.type == 2 && !invoiceTypeInput.label,
                }"
              ></span> -->
            </div>
            <div class="right_item tab bill_item" :class="{
                  checked: invoiceCurrentType == '6、行政事业单位非经营服务性收入收款收据',
                }" @click="handleInvoiceType(3, '6、行政事业单位非经营服务性收入收款收据')">
              8 行政事业单位非经营服务性收入收款收据
              <!-- <span
                class="check"
                :class="{
                  active: invoiceType.type == 2 && !invoiceTypeInput.label,
                }"
              ></span> -->
            </div>
            <div class="right_item tab bill_item" :class="{
                  checked: invoiceCurrentType == '7、其他票据',
                }" @click="handleInvoiceType(3, '7、其他票据')">
              9 其他票据
              <!-- <span
                class="check"
                :class="{
                  active: invoiceType.type == 2 && !invoiceTypeInput.label,
                }"
              ></span> -->
            </div>
            <!-- <div class="right_item">
              3.其他有效票据：
              <input
                type="text"
                placeholder=""
                v-model="invoiceTypeInput.label"
                style="border: 0.01px solid #000000; padding: 0.05rem 0.1rem"
              />
            </div> -->
          </div>
        </div>
        <!-- <div class="invoice_list">
          <div
            class="invoice_item tab"
            :class="{ active: invoiceTypeInput.label === item.name }"
            @click="handleInvoiceInputType(item)"
            v-for="(item, index) in invoiceList"
            :key="index"
          >
            {{ item.name }}
          </div>
        </div> -->
        <div>
          <!-- 上传 -->
          <div class="bs">温馨提示:请上传相关票据图片</div>
          <el-upload
            class="avatar-uploader"
            :headers="{ token: $store.state.userInfo.token }"
            :action="constant.UPLOAD_URL + '/file/upload'"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
        <div class="tab infoConfirm-agree" @click="invoiceCheck = !invoiceCheck">
          <span :class="{ active: invoiceCheck }" style="background-color: transparent !important;"></span>
          已知悉以上条件，自愿申报
        </div>
        <div>
          <!-- <div class="invoice_submit tab" @click="isActiveIndex = 1">上一步</div> -->
          <div class=" tab infoConfirm-submit" @click="invoiceSubmit">确认申报</div>
        </div>
      </div>
    </div>
    <!-- 确认申报流程 -->
    <div class="step3" v-show="isActiveIndex === 3">
      <div class="steptit">申报流程说明</div>
      <div class="describe w1200">
        恭喜您入选首批全国足球特色幼儿园示范园项目，请您按照以下四步流程完成示范园线上申报确认工作：<br />
        第1步：确认申报流程；<br />
        第2步：完善申报信息；<br />
        第3步：预览协议，确认申报；<br />
        第4步：完成申报。<br />
      </div>
      <div class="btnsub tab" @click="isActiveIndex = 4">我已确认</div>
    </div>
    <!-- 完善信息 -->
    <div class="step4" v-show="isActiveIndex === 4">
      <div class="steptit">完善信息</div>
      <div class="receivepay w1200">
        <div class="tit">请您填写收款信息</div>
        <div class="desc">
          <p>
            说明：<br />1.该收/付款账号为同一账号，主要用于园所接受资助金资助、以及为全国足球特色幼儿园系统平台定向服务打款使用。<br />2.此园所地址需保证此次及今后收发快递畅通，请仔细填写。
          </p>
          <div>
            <div class="layui-form-label">备案号：</div>
            {{ this.$store.state.schoolInfo.atteSchool.sn }}
          </div>
          <!-- <div>
            <div class="layui-form-label">园所名称：</div>
            <input
              type="text"
              name="address"
              required
              lay-verify="required"
              placeholder="请输入园所地址"
              autocomplete="off"
              class="layui-input"
              v-model="collectionList.address"
            />
            {{ $store.state.schoolInfo.atteSchool.schoolName }}
          </div> -->
        </div>
        <form class="layui-form" style="width: 75%; margin: 0 atuo">
          <div class="layui-form-item">
            <label class="layui-form-label"><i>*</i>园所名称：</label>
            <div class="layui-input-block">
              <input
                type="text"
                name="address"
                required
                lay-verify="required"
                placeholder="请输入园所名称"
                autocomplete="off"
                class="layui-input"
                v-model="collectionList.spSchoolName"
              />
            </div>
            <div class="tips">此处名称应该与公章企业名称一致。</div>
          </div>
          <div class="layui-form-item">
            <label class="layui-form-label"><i>*</i>园所地址：</label>
            <div class="layui-input-block">
              <input
                type="text"
                name="address"
                required
                lay-verify="required"
                placeholder="请输入园所地址"
                autocomplete="off"
                class="layui-input"
                v-model="collectionList.address"
              />
            </div>
            <div class="tips">此园所地址需保证此次及今后收发快递畅通</div>
          </div>
          <div class="layui-form-item">
            <label class="layui-form-label"><i>*</i>法定代表人：</label>
            <div class="layui-input-block">
              <input
                type="text"
                name="contacts"
                required
                lay-verify="required"
                placeholder="请输入法定代表人姓名"
                autocomplete="off"
                class="layui-input"
                v-model="collectionList.contacts"
              />
            </div>
          </div>
          <div class="layui-form-item">
            <label class="layui-form-label"><i>*</i>法人身份证：</label>
            <div class="layui-input-block">
              <input
                type="text"
                name="idCard"
                required
                lay-verify="required"
                placeholder="请输入法人身份证"
                autocomplete="off"
                class="layui-input"
                v-model="collectionList.idCard"
              />
            </div>
          </div>
          <div class="layui-form-item">
            <label class="layui-form-label"><i>*</i>开户单位名称：</label>
            <div class="layui-input-block">
              <input
                type="text"
                name="companyName;"
                required
                lay-verify="required"
                placeholder="请输入开户单位名称"
                autocomplete="off"
                class="layui-input"
                v-model="collectionList.companyName"
              />
            </div>
            <div class="tips">开户单位名称用于开具发票与汇款</div>
          </div>
          <div class="layui-form-item">
            <label class="layui-form-label"><i>*</i>开户行：</label>
            <div class="layui-input-block">
              <input
                type="text"
                name="bankName"
                required
                lay-verify="required"
                placeholder="请输入开户行"
                autocomplete="off"
                class="layui-input"
                v-model="collectionList.bankName"
              />
            </div>
            <div class="tips">
              请您输入开户行的名称（支行信息也需要填写），例如北京招商银行望京支行
            </div>
          </div>
          <div class="layui-form-item">
            <label class="layui-form-label"><i>*</i>银行账号：</label>
            <div class="layui-input-block">
              <input
                type="text"
                name="bankAccount"
                required
                lay-verify="required"
                placeholder="请输入银行账号"
                autocomplete="off"
                class="layui-input"
                v-model="collectionList.bankAccount"
              />
            </div>
            <div class="tips">
              该收/付款账号为同一账号，主要用于园所接受资助金资助、以及为全国足球特色幼儿园系统平台定向服务打款使用
            </div>
          </div>
          <!-- <div class="invoice_submit tab"  @click="isActiveIndex = 2">上一步</div>
          <div class="invoice_submit tab" @click="handCollection()">下一步</div> -->
          <div style="text-align: center">
            <span
              class="btnsub tab"
              @click="isActiveIndex = 2"
              style="display: inline-block; width: 2rem; margin-right: 30px"
              >上一步</span
            >
            <span
              class="btnsub tab"
              @click="handCollection()"
              style="display: inline-block; width: 2rem"
              >下一步</span
            >
          </div>
        </form>
      </div>
    </div>
    <!-- 完善发票信息 -->
    <div class="step9" v-show="isActiveIndex === 5">
      <div class="steptit">完善发票信息</div>
      <div class="receivepay w1200">
        <div class="tit">请您填写发票信息</div>
        <div class="desc">
          <p>说明：此发票为幼儿园购买定向服务发票信息</p>
          <br />
          <br />
          <div>
            <div class="layui-form-label">开户单位名称：</div>
            {{ this.collectionList.companyName }}
          </div>
          <div>
            <div class="layui-form-label">开户行：</div>
            {{ this.collectionList.bankName }}
          </div>
          <div>
            <div class="layui-form-label">银行账号：</div>
            {{ this.collectionList.bankAccount }}
          </div>
        </div>
        <form class="layui-form" style="width: 75%; margin: 0 atuo">
          <div class="layui-form-item">
            <label class="layui-form-label"><i>*</i>邮箱：</label>
            <div class="layui-input-block">
              <input
                type="text"
                required
                lay-verify="required"
                placeholder="请输入邮箱："
                autocomplete="off"
                class="layui-input"
                v-model="invoicePerfect.spEmail"
              />
            </div>
          </div>
          <div class="layui-form-item">
            <label class="layui-form-label"><i>*</i>统一社会信用代码：</label>
            <div class="layui-input-block">
              <input
                type="text"
                required
                lay-verify="required"
                placeholder="请输入统一社会信用代码"
                autocomplete="off"
                class="layui-input"
                v-model="invoicePerfect.creditCode"
              />
            </div>
            <div class="tips" style="margin-left: 3.2rem">
              请您填写发票中所需要的统一社会信用代码(税号)
            </div>
          </div>
        </form>
      </div>
      <!-- <div class="btnsub tab" @click="handInvoice()">下一步</div> -->
      <div style="text-align: center">
        <span
          class="btnsub tab"
          @click="isActiveIndex = 4"
          style="display: inline-block; width: 2rem; margin-right: 30px"
          >上一步</span
        >
        <span
          class="btnsub tab"
          @click="handInvoice()"
          style="display: inline-block; width: 2rem"
          >下一步</span
        >
      </div>
    </div>
    <!-- 预览协议,确认申报 -->
    <div class="step5" v-show="isActiveIndex === 7">
      <div class="steptit">合同展示</div>
      <form class="layui-form" lay-filter="forms4">
        <div class="pact w1200">
          <div class="choose tab" @click="isActiveIndex = 52">
            <div class="box">
              <div class="name">示范园项目资助<br />协议书</div>
            </div>
          </div>
          <div class="choose tab" @click="isActiveIndex = 51">
            <div class="box">
              <div class="name">定向服务购买<br />协议</div>
            </div>
          </div>
        </div>
        <div class="agree">
          <input name="bank" type="text" />
          <input name="user" type="hidden" />
          <input name="company" type="hidden" />
          <input name="account" type="hidden" />
          <input name="id_card" type="hidden" />
          <input name="mail" type="hidden" />
          <input name="creditCode" type="hidden" />
          <input name="address" type="hidden" />
          <input
            type="checkbox"
            name="agree"
            id="agree"
            title="我已阅读《示范园项目资助协议书》及《定向服务购买协议》，清楚申报流程，自愿参加全国足球特色幼儿园示范园（试点）项目,<div style='font-size: .18rem;color: #cc1a30;'>说明：此版本为协议模版，如与最终协议存在细微出入，请以最终签约版本为准。</div>"
            lay-skin="primary"
            lay-filter="agree"
          />
        </div>
        <div class="invoice_check tab" style="text-align: center">
          <el-checkbox v-model="checked"
            >我已阅读《示范园项目资助协议书》及《定向服务购买协议》，清楚申报流程，自愿参加全国足球特色幼儿园示范园（试点）项目</el-checkbox
          >
          <div style="color: red">
            说明：此版本为协议模版，如与最终协议存在细微出入，请以最终签约版本为准。
          </div>
        </div>
        <div></div>
        <!-- <a class="btndis" id="time"></a> -->
        <!-- 申报按钮 -->
        <div style="text-align: center">
          <span
            class="btnsub tab"
            @click="isActiveIndex = 5"
            style="display: inline-block; width: 2rem; margin-right: 30px"
            >上一步</span
          >
          <span
            class="btnsub tab"
            @click="this.handDeclare"
            style="display: inline-block; width: 2rem"
            >确认申报</span
          >
        </div>
        <!-- <div class="btnsub tab" @click="this.handDeclare">确认申报</div> -->
        <!-- <button
        :disabled="checked"
          class="btnsub" 
          lay-submit
          lay-filter="checkagree"
          id="confirm"
        >
          确认申报
        </button> -->
      </form>
    </div>
    <!-- 定向服务购买协议 -->
    <div class="step6" v-show="isActiveIndex === 51">
      <div class="steptit">《定向服务购买协议》</div>
      <div class="protocol w1200">
        <div class="box">
          <!-- 协议开始 -->
          <p class="18" style="text-align: right">合同编号：</p>
          <p class="18">&nbsp;</p>
          <p class="18">&nbsp;</p>
          <p class="18">&nbsp;</p>
          <p class="18" style="text-align: center">
            <img width="605" height="152" src="../../assets/img/htc.png" />
          </p>

          <p class="MsoNormal" align="center" style="text-align: center">
            <b>&nbsp;</b>
          </p>
          <p class="MsoNormal" align="center" style="text-align: center">
            <b>&nbsp;</b>
          </p>
          <p
            class="MsoNormal"
            align="center"
            style="text-align: center; font-size: 0.24rem"
          >
            <b>定向服务购买协议</b>
          </p>
          <p class="MsoNormal" align="center" style="text-align: center">
            <b>&nbsp;</b>
          </p>
          <p class="MsoNormal" align="center" style="text-align: center">
            <b>&nbsp;</b>
          </p>
          <p class="MsoNormal" style="text-indent: 21.1pt">
            <b>甲方</b><span>：</span><a name="_Hlk43558084"></a
            ><u>小牛体育文化发展（重庆）有限公司</u>
          </p>
          <p class="MsoNormal" style="text-indent: 21.1pt">
            <b>乙方</b>：<u
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u
            ><u
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u
            ><u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>&nbsp;&nbsp;&nbsp;&nbsp;<b
              >幼儿园</b
            ><b>备案号</b><b>:</b
            ><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b
            ><b
              ><u
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u
              ></b
            >
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            甲、乙双方为友好合作，经协商一致，按照国家法律、法规及相关行业政策，签订本协议，以兹共守。
          </p>
          <p class="MsoNormal" style="text-indent: 21.1pt">
            <b><span>一、</span> <span>服务内容与方式</span></b
            ><b></b>
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            1.&nbsp;<span>甲方通过</span>“全国足球特色幼儿园系统平台”（以下简称“系统平台”，包括但不限于网站、微信公众号、微信小程序、微信插件及其他方式等）向乙方提供“<a
              name="_Hlk43557838"
            ></a
            ><span>全国足球特色幼儿园线上</span
            ><span>视频游戏教学课程</span>”——“Let’s Get
            Active线上课程”（以下简称“LGA线上课程”），具体服务范围及内容根据乙方选购课程确定。
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            2.&nbsp;甲方通过第<u>&nbsp;&nbsp;&nbsp;&nbsp;</u><u>1</u
            ><u>&nbsp;&nbsp;&nbsp;&nbsp;</u>&nbsp;<span
              >种平台向乙方提供服务：</span
            >
          </p>
          <p class="MsoNormal" style="margin-left: 64.2pt; text-indent: -36pt">
            （1）&nbsp;网站（网址：<a href="http://www.prefootball.cn"
              ><u>www.prefootball.cn</u></a
            >）；
          </p>
          <p class="MsoNormal" style="margin-left: 64.2pt; text-indent: -36pt">
            （2）&nbsp;微信公众号；
          </p>
          <p class="MsoNormal" style="margin-left: 64.2pt; text-indent: -36pt">
            （3）&nbsp;其他方式：<u
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u
            ><u></u>
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            3.&nbsp;根据乙方要求，甲方向乙方提供以下服务。
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            <span>（</span>1）幼儿园基础信息管理体系（KFI） Kindergarten
            Foundational Information；
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            <span>（</span>2）幼儿足球游戏课程体系（LGA）Let's Get Active；
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            （3）<span>幼儿足球游戏测试体系（</span>PDA）Physical Data Archives
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            <span>（</span>4）<span>幼儿足球运动评估体系</span>(PES) Physical
            Evaluation System
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            <span>（</span
            >5）幼儿园<span>全国综合量化评估体系</span>(KQA）Kindergarten
            Quantitative Assessment
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            <span>（</span>6）幼儿足球教师培训<span>体系</span>(TCP)Teachers
            Certification of Prefootball
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            <span>（</span>7）<span>小小运动会</span>(KSFA)&nbsp;Kids Sports for
            All
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            4.&nbsp;为保障甲方整体用户需求，为广大用户提供更好服务，甲方有权修改服务方式、优化服务版本、升级服务系统；为提高甲方服务水平，甲方有权随时丰富服务内容、范围，优化服务方式，并有权调整系统平台及服务的名称、规格和功能等。
          </p>
          <p class="MsoNormal" style="text-indent: 21.1pt">
            <a name="page2"></a><b>二、合同价款与服务期限</b><b></b>
          </p>
          <p class="MsoNormal" style="margin-left: 0pt; text-indent: 21pt">
            1.&nbsp;<span>乙方协议代码（即其统一社会信用代码及其法定代表人</span>/授权代表人身份证号码）
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            统一社会信用代码（或其他有效证件代码）为：<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>；
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            <span>法定代表人</span>/授权代表人为：<u>&nbsp;&nbsp;&nbsp;</u
            ><u>&nbsp;</u
            ><u>&nbsp;&nbsp;&nbsp;&nbsp;</u
            >，身份证号码为：<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>。
          </p>
          <p class="MsoNormal" style="margin-left: 0pt; text-indent: 21pt">
            2.&nbsp;合同总价：
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            <span>（</span>1）本协议服务单价为100<span>元</span>/ID/学期。
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            <span>（</span
            >2）乙方确认向甲方购买200<span>个</span>ID账号，共购买1个学期（一个学期即六个月）服务。
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            <span>（</span>3）本协议总价为：<b>20000元（大写金额：贰万元整）</b
            ><span>，</span>100 * 200<span>（</span>ID个数）*
            1&nbsp;<span>（学期个数）</span>= 20000元。
          </p>
          <p class="MsoNormal" style="margin-left: 0pt; text-indent: 21pt">
            3.&nbsp;付款方式：<u></u>
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            乙方在收到中国足球发展基金会资助金后，<u>三个工作日</u>内，向甲方一次性支付本协议总价款。
          </p>
          <p class="16" style="margin-left: 21pt; text-indent: -21pt">
            4.&nbsp;收款信息：
          </p>
          <p class="16" style="margin-left: 21pt">
            &nbsp;<span>开户单位：小牛体育文化发展（重庆）有限公司</span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </p>
          <p class="16" style="margin-left: 21pt; text-indent: 5.25pt">
            <span>账号：</span>50050100414100003379
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </p>
          <p class="16" style="margin-left: 21pt; text-indent: 5.25pt">
            开户行：中国建设银行股份有限公司重庆中山路支行
          </p>
          <p class="MsoNormal" style="text-indent: 21.1pt">
            <b>三、双方权利与义务</b><b></b>
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            1.&nbsp;甲方负责提供服务内容中的课程和教材，并保证所有内容均是正版的、拥有合法权利或合法授权的。保证绝对不会侵犯任何第三方的著作权、商标权、肖像权、姓名权、名誉权、隐私权或者其他合法权益。
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            2.&nbsp;甲方将采取合理措施保护乙方合规性操作数据、帐户、密码等信息安全，但如因乙方及其管理人员及第三方原因导致信息丢失或泄露的，与甲方无关。
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            3.&nbsp;甲方有权自行决定对服务平台的版本、功能进行变更、升级、优化，有权对服务方式、服务内容进行丰富与优化。乙方同意，甲方无需因上述行为特意通知乙方，并对乙方及乙方管理人员及任何第三人不承担任何责任。
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            4.&nbsp;甲方自支付完成起<u>&nbsp;<span>五</span> </u
            >个工作日内为乙方开通相应账号、提供选购服务。如系乙方原因（包括但不限于乙方违约、乙方提供资料或信息存在问题等）导致甲方未能如期为乙方开通账号或提供服务的，由乙方自行承担，甲方服务自本协议到期后自动终止，不予顺延；如系甲方无正当理由未能如期为乙方开通账号并提供服务的，甲方服务相应顺延，服务时间自甲方实际提供服务之日起算。
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            5.&nbsp;乙方承诺本协议项下的服务只用于非商业用途。承诺未经甲方书面许可，不对甲方提供的服务及其他自甲方获悉的任何信息或成果，进行复制、出租、出借、出售、或通过其他方式转让、披露于第三人，或通过剪切、编辑、录制及其他方式生成衍生作品。否则，无论是否基于商业目的，均应向甲方承担违约责任，并赔偿甲方一切损失。
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            6.&nbsp;<span>乙方不得基于商业目的模仿甲方的产品和</span>/或服务；不得复制或模仿甲方的设计理念、界面、功能和图表；未经甲方许可不得对甲方产品/服务的内容进行修改或制造其他派生产品。
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            7.&nbsp;<span>乙方不得利用甲方平台及提供产品</span>/服务进行任何违法或不当的活动，不得在系统平台发表任何违法或不当言论。
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            8.&nbsp;<span>甲方仅向乙方提供产品</span>/服务。乙方在组织内部人员学习过程中，应自行对其内部人员、参与人员、管理人员的安全负责，如有安全问题与甲方无关。
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            9.&nbsp;<span>乙方按照实名制为其学员购买</span>ID账号及对应服务，每个ID账号仅由实名注册学员单独使用，不可多学员重复使用，否则，乙方应按照实际使用学员人数（即应购买ID个数）向甲方补充相应服务费用，并支付服务费用的30%作为违约金。
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            10.&nbsp;乙方如要求开具发票，可自行在<a
              href="http://www.prefootball.cn-my/"
              >“www.prefootball.cn-My </a
            ><span>幼儿园</span>- 费用管理-发票”，向甲方申请开具正规增值税发票。
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            11.&nbsp;乙方在成功购买服务后，所支付的服务费用均不作退返。但在下列情况下，甲方同意将乙方订购费用中的剩余款项无息退返：
          </p>
          <p class="MsoNormal" style="margin-left: 64pt; text-indent: -36pt">
            （1）&nbsp;非因乙方原因，甲方无正当理由终止提供服务的；
          </p>
          <p class="MsoNormal" style="margin-left: 64pt; text-indent: -36pt">
            （2）&nbsp;存在其他甲方特别声明退款情形的。
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            12.&nbsp;<span>甲方现行免费提供的产品</span>/服务，不应视为甲方放弃收费的权利。甲方随时有权取消免费服务，并收取相应费用。
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            13.&nbsp;甲方有权根据实际情况随时单方调整费用标准及收费方式，并公示于系统平台，而无需获得乙方同意。若调整日前乙方已经订购服务并已全额支付费用的，已购买的服务不受影响。
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            14.&nbsp;乙方应按照本协议约定及系统平台用户服务协议、甲方声明及规则、补充协议等规定，在授权范围内享受服务，如乙方违反相关约定的，应向甲方承担违约责任，并赔偿甲方一切损失。如因乙方原因，导致第三方侵害甲方合法权益的，乙方与该第三方向甲方承担连带责任；如因乙方原因导致甲方向第三方进行赔偿的，甲方有权向乙方追偿。所指损失，包括但不限于甲方的直接损失、间接损失、逾期利益损失、为及时止损所支付的合理费用、因乙方侵权或违约行为向第三方所支付的费用，以及为维权所支付调查费、保全费、鉴定费、诉讼费、律师费、差旅费等全部费用。
          </p>
          <p class="MsoNormal" style="text-indent: 21.1pt">
            <b>四、违约责任</b><b></b>
          </p>
          <p class="MsoNormal" style="margin-left: 0pt; text-indent: 21pt">
            1.&nbsp;<span>甲方应如期为乙方提供服务，逾期未能提供的，按照本协议第三条第</span>4款之约定承担法律责任。
          </p>
          <p class="MsoNormal" style="margin-left: 0pt; text-indent: 21pt">
            2.&nbsp;乙方应按照本协议之约定及时履行付款义务，逾期付款的，每逾期一日，按照本协议总价款的<u
              >&nbsp;&nbsp;5 ‰</u
            >向甲方支付违约金；逾期超过三十日的，甲方有权解除本协议，同时要求乙方支付违约金。
          </p>
          <p class="MsoNormal" style="text-indent: 21.1pt">
            <b>五、知识产权保护与保密条款</b><b></b>
          </p>
          <p class="MsoNormal" style="margin-left: 0pt; text-indent: 21pt">
            1.&nbsp;甲方向乙方所提供的一切内容（包括但不限于文字、图片、音频、视频、图表、界面设计、版面框架、有关数据、电子文档、软件或应用程序等）的一切著作权、商标权、专利权、商业秘密等知识产权，均归甲方所有，乙方仅在授权范围内享有使用权。
          </p>
          <p class="MsoNormal" style="margin-left: 0pt; text-indent: 21pt">
            2.&nbsp;甲方需采取合理的措施保护乙方的信息。除法律法规规定的情形外，未经乙方许可甲方不得向第三方公开、透露用户信息。但为保障服务质量与效率、便于行政管理与监督，乙方同意甲方向预先选定的合作方及教育主管部门提供乙方信息。
          </p>
          <p class="MsoNormal" style="margin-left: 0pt; text-indent: 21pt">
            3.&nbsp;乙方应就其自甲方所获取的一切产品、服务及内容予以保密，未经甲方书面许可，无论其是否存在商业目的，均不得以任何形式向第三方予以披露，否则应向甲方承担违约责任，并赔偿甲方一切损失。
          </p>
          <p class="MsoNormal" style="margin-left: 0pt; text-indent: 21pt">
            4.&nbsp;各方对在履行协议中获得的商业机密和技术秘密负保密责任。但出现下列任一情况时，甲方有权披露乙方信息：
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            <span>（</span>1）
            应人民银行或银监会、证监会、保监会等金融业监管机构要求的；
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            <span>（</span>2）
            应公安机关、人民法院、人民检察院、教育部或有权行政机关要求的；
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            <span>（</span>3）根据法律规定甲方应当向第三方提供信息的其他情形。
          </p>
          <p class="MsoNormal" style="margin-left: 0pt; text-indent: 21pt">
            5.&nbsp;各方保证其本身及其雇员、代理人、管理人员履行本协议保密义务，保密期限为永久。
          </p>
          <p class="MsoNormal" style="text-indent: 21.1pt">
            <b>六、争议解决</b><b></b>
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            甲乙双方发生任何纠纷或争议，首先应友好协商解决；协商不成的，任何一方有权向甲方所在地有管辖权的人民法院提起诉讼。
          </p>
          <p class="MsoNormal" style="text-indent: 21.1pt">
            <b>七、其他说明</b><b></b>
          </p>
          <p class="MsoNormal" style="margin-left: 0pt; text-indent: 21pt">
            1.&nbsp;本协议未尽事宜，由双方另行协商并签订书面补充协议确定。补充协议系本协议的组成部分，其约定与本协议不一致的，以补充协议为准。
          </p>
          <p class="MsoNormal" style="margin-left: 0pt; text-indent: 21pt">
            2.&nbsp;系统平台用户服务协议、甲方声明与使用规则等是本协议的有效组成部分，与本协议具有同等法律效力。
          </p>
          <p class="MsoNormal" style="margin-left: 0pt; text-indent: 21pt">
            3.&nbsp;本协议原则上采用线上签订模式，以系统平台上传备案协议内容为准，甲乙双方可自行下载备案协议，下载文本具有同等法律效力。
          </p>
          <p class="MsoNormal" style="margin-left: 0pt; text-indent: 21pt">
            4.&nbsp;本协议自甲乙双方签字或盖章之日起生效。
          </p>
          <p class="MsoNormal" style="margin-left: 28pt">（以下无正文）</p>
          <p class="MsoNormal"><b>&nbsp;</b></p>
          <p class="MsoNormal"><b>&nbsp;</b></p>
          <p class="MsoNormal" style="margin-left: 28.05pt">
            <b>甲方（盖章）：小牛体育文化发展（重庆）有限公司</b><b></b>
          </p>
          <p class="MsoNormal" style="margin-left: 28.05pt">
            <span>法定代表人</span>/授权代理人（签字）：<u></u>
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u
            >年<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>月<u>&nbsp;&nbsp;&nbsp;</u>日
          </p>
          <p class="MsoNormal">&nbsp;</p>
          <p class="MsoNormal" style="margin-left: 28.05pt">
            <b
              ><span>乙方（盖章）：</span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b
            ><b></b>
          </p>
          <p class="MsoNormal" style="margin-left: 28.05pt">
            <span>法定代表人</span>/授权代理人（签字）：<u
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u
            ><u></u>
          </p>
          <p class="MsoNormal" style="text-indent: 21pt">
            <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u
            >年<u>&nbsp;&nbsp;&nbsp;&nbsp;</u>月<u>&nbsp;&nbsp;&nbsp;</u>日
          </p>
          <p class="MsoNormal">&nbsp;</p>
          <!-- 协议结束 -->
        </div>
      </div>
      <!-- <a class="btndis" id="time"></a> -->
      <a class="btnsub tab" id="back" @click="isActiveIndex = 7">返回</a>
    </div>
    <!-- 《示范园项目资助协议书》 -->
    <div class="step7" v-show="isActiveIndex === 52">
      <div class="steptit">《示范园项目资助协议书》</div>
      <div class="protocol w1200">
        <div class="box">
          <!-- 协议开始 -->
          <div class="Section0">
            <p class="18" style="text-align: right">合同编号：</p>
            <p class="15">&nbsp;</p>
            <p class="15">&nbsp;</p>
            <p class="15">&nbsp;</p>
            <p class="15" style="text-align: center">
              <img
                width="605"
                height="156"
                src="../../assets/img/htt.png"
              />&nbsp;
            </p>
            <p class="MsoNormal" align="center" style="text-align: center">
              <b>&nbsp;</b>
            </p>
            <p class="MsoNormal" align="center" style="text-align: center">
              <b>&nbsp;</b>
            </p>
            <p
              class="MsoNormal"
              align="center"
              style="text-align: center; font-size: 0.24rem"
            >
              <b>资助协议书</b>
              <b> </b>
            </p>
            <p class="MsoNormal" align="right" style="text-align: right">
              <br />
            </p>
            <p class="MsoNormal" align="right" style="text-align: right">
              <span>足基青字〔2021〕1号</span>
            </p>
            <p class="MsoNormal" align="right" style="text-align: right">
              <br />
            </p>
            <div class="Section0">
              <p class="MsoNormal">甲方：中国足球发展基金会<b></b></p>
              <p
                class="MsoNormal"
                style="margin-left: 42pt; text-indent: -42pt"
              >
                <span>地址：北京市朝阳区工人体育场东路工人体育场东</span
                >2门内2号楼
              </p>
              <p class="MsoNormal">法定代表人：王战和</p>
              <p class="MsoNormal">&nbsp;</p>
              <p class="MsoNormal">乙方：</p>
              <p class="MsoNormal">园所编号：</p>
              <p class="MsoNormal">地址：</p>
              <p class="MsoNormal">法定代表人：</p>
              <p class="MsoNormal">&nbsp;</p>
              <p class="MsoNormal" style="text-indent: 28pt">
                <span
                  >为全面贯彻全国教育大会精神，推进全国幼儿足球科学发展，中国足球发展基金会（以下简称基金会）与全国青少年校园足球工作领导小组办公室（以下简称全国校足办）联合发起</span
                >“全国足球特色幼儿园示范园（试点）项目”，打造科学的幼儿足球活动样板，整体提升全国幼儿足球教育水平，引导各级各类幼儿园广泛开展幼儿足球活动，促进幼儿身心健康全面发展，培养德智体美劳全面发展的社会主义建设者和接班人。
              </p>
              <p class="15">&nbsp;</p>
              <p class="MsoNormal">
                &nbsp;&nbsp;&nbsp;&nbsp;<span>一、项目名称</span>
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                全国足球特色幼儿园示范园（试点）项目（以下简称示范园项目）。
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">二、项目简介</p>
              <p class="MsoNormal" style="text-indent: 28pt">
                <span
                  >以《全国足球特色幼儿园游戏活动指南》为纲，在全国足球特色幼儿园系统平台（</span
                >www.prefootball.cn）全面开展幼儿足球教学活动。按照系统平台的科学要求，从幼儿足球的教学、训练、测试、评估、量化积分、教师培训、小小运动会等多方面系统完成教学活动，并在项目期内完整上传园所相关资料及数据。
              </p>
              <p class="MsoNormal" style="text-indent: 20.55pt">
                三、资助内容和金额
              </p>
              <p class="MsoNormal" style="margin-left: 0pt; text-indent: 21pt">
                （一）<b>资助内容</b><b></b>
              </p>
              <p class="MsoNormal">
                &nbsp;&nbsp;&nbsp;&nbsp;<span>按照基金会给全国校足办的《中国足球发展基金会关于资助全国足球特色幼儿园示范园试点项目的复函》（</span>足基函字<span>〔</span>2021〕19号）要求，结合与全国校足办协商确定的示范园项目内容，本项目资助内容包括但不限于在全国足球特色幼儿园系统平台的以下教学活动：
              </p>
              <p class="MsoNormal" style="text-indent: 27.55pt">
                <b>1、</b
                ><b><span>幼儿足球游戏课程体系（</span>LGA）Let's Get Active</b
                ><b></b>
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                该<span>课程体系采用</span>“6+1”主题模式，分别是“体操、舞蹈、跑跳能力、身体管理、合作与解决问题、球类运动”+“足球游戏”，覆盖幼儿园小班<span>（</span>3-4岁）、中班<span>（</span>4-5岁）、大班<span>（</span>5-6岁）三个年级，每学期16课时，每周1节课。
              </p>
              <p class="MsoNormal" style="text-indent: 27.55pt">
                <b>2、</b
                ><b
                  ><span>幼儿足球游戏测试体系（</span>PDA）Physical Data
                  Archive</b
                ><b></b>
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                <span
                  >该体系以游戏测评形式，对幼儿身体基础指标及运动能力进行测试、分类及报告。</span
                >PDA测试体系从基础和运动两个层面进行分析，基础数据包括八项指标，包括身高、体重、臂展、胸围、肺活量、视力、坐立体前屈、体脂率；运动测试包括八大维度，包括跑动、跳跃、敏捷、平衡、协调、力量、物体控制（手）、物体控制（脚）。
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                3、<b><span>幼儿足球运动评估体系</span>(PES)</b><b>&nbsp;</b
                ><b>Physical Evaluation System</b><b></b>
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                该体系是对幼儿体能发展的数据化体现，将幼儿体质测试数据和运动测试数据进行统计与评估，并生成评估报告反馈给所在幼儿园及家长。通过数据对比，了解和把握幼儿个体的阶段性发展和身心状况，为后期制定幼儿健康活动提供科学依据。
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                4、<b>幼儿</b><b>园</b
                ><b
                  ><span>全国综合量化评估体系</span>(KQA）Kindergarten
                  Quantitative Assessmen</b
                ><b>t</b><b></b>
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                该体系由基础积分、教师积分和幼儿积分三个部分组成，以完整申报幼儿园基础信息管理体系<span>（</span>KMI）信息为基础，以可视化和数据化的形式呈现结果、反馈和改良建议报告，多维度评估幼儿园幼儿足球开展情况。
              </p>
              <p class="MsoNormal" style="text-indent: 27.55pt">
                <b>5、</b><b>幼儿足球教师</b><b>培训</b
                ><b><span>体系</span>(TC</b><b>P</b
                ><b>)Teachers Certification of </b><b>Prefootball</b><b></b>
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                该体系主要分为基础普及培训和分级主题培训两大类，主要内容包括：幼儿足球发展政策、理念、方法体系课程；幼儿足球线上教学系统、数据平台实用课程；经典幼儿足球课程讲解及示范教学课程；幼儿情感、心理学基础类课程；运动健康、营养及救护基础课程等。
              </p>
              <p class="MsoNormal" style="text-indent: 27.55pt">
                <b>6、</b><b><span>小小运动会</span>(KSFA)</b><b>&nbsp;</b
                ><b>Kids Sports for All</b><b></b>
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                在园所及所在地区通过多维运动和游戏活动的趣味组合，形成生动有趣的运动会或嘉年华，体验运动快乐，感受竞争乐趣，培养规则意识及协作精神，激发运动潜能。
              </p>
              <p class="MsoNormal" style="margin-left: 0pt; text-indent: 21pt">
                （二）<b>资助金额</b><b></b>
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                甲方一次性资助<span>乙方</span>20000元（大写：贰万元整人民币），用于乙方园内200名幼儿（注册数）2020-2021学年度一学期在全国足球特色幼儿园系统平台开展幼儿足球教学活动经费。
              </p>
              <p class="MsoNormal" style="text-indent: 27.4pt">
                四、双方权利和义务
              </p>
              <p class="MsoNormal" style="text-indent: 28.1pt">
                <b>（一）甲方权利和义务</b><b></b>
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                1.甲方资助乙方的资金为2020-2021年度体育总局向甲方购买服务资金，保证合法有效。
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                2.甲方为公益慈善组织，且资助乙方的资金来源为财政部安排的中央专项彩票公益金，有权按照民政部和财政部相关规定,聘请第三方会计师事务所，对该项目进行专项审计，也可要求乙方提供具有法律效力的第三方审计报告。
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                3.甲方依据国家有关规定，对本项目实施情况和资助资金使用情况进行监督管理，乙方若未按本协议履约，甲方有权解除本协议并追偿资助资金。
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                4.甲方开票信息：
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                单位名称：中国足球发展基金会
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                <span>纳税人识别号：</span>5310 0000 MJ 0065 0654
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                <span>电话</span>:010-67189231
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                5.甲方在本协议生效并收到乙方开具的符合国家规定的票据后，将资助款一次性汇至乙方账户，双方账户信息如下：
              </p>
              <p class="15">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
              <p class="15" style="text-indent: 28pt">1）甲方账户信息：</p>
              <p class="MsoNormal" style="text-indent: 28pt">
                户名：中国足球发展基金会
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                <span>账号：</span>1105 0162 3600 0000 0266
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                开户行：中国建设银行北京前门支行
              </p>
              <p class="15" style="text-indent: 32pt">2）乙方账户信息</p>
              <p class="15" style="text-indent: 32pt">户名：</p>
              <p class="15" style="text-indent: 32pt">账号：</p>
              <p class="15" style="text-indent: 32pt">开户行：</p>
              <p class="MsoNormal" style="text-indent: 28.1pt">
                <b>（二）乙方权利义务</b><b></b>
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                1.乙方确保按照全国足球特色幼儿园系统平台的要求，保证甲方的资助资金用于该项目，并确保资金使用合法、合规、合理、有效，勤俭节约、杜绝浪费。
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                2.乙方需按照全国校足办要求完成示范园项目：
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                ——教育理念：园所需全面贯彻落实全国教育大会精神，牢固树立健康第一的教育理念，帮助幼儿在体育锻炼中享受乐趣、增强体质、健全人格、锤炼意志。以指南为纲，贯彻校园足球八大体系指导方针，按照“教会、勤练、常赛”的先进理念，开展幼儿足球教学活动。
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                ——资格要求：园所需达到在园儿童200人以上规模。
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                ——教学要求：园所自愿参加该项目，需按照全国校足办及中国足球发展基金会要求进行申报、签约、教学、数据回馈等相关工作，严格按照全国足球特色幼儿园系统平台所提供的教学体系要求全面开展教学活动，上传相关数据真实有效，不得出现虚假瞒报行为。
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                ——资金管理：资助资金应专向用于示范园项目，不得挪作他用。
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                ——考核要求：全国校足办将定期对试点园所进行考核，考核标准及园所数据详见全国足球特色幼儿园系统平台。
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                3.应在乙方有关本项目的所有活动、宣传和仪式上展示中国足球发展基金会LOGO，并注明“彩票公益金活动—中国体育彩票和中国福利彩票”字样。
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                4.乙方须接受国家财政、审计部门和甲方委派社会中介机构进行的专项审计和绩效评价。
              </p>
              <p class="MsoNormal" style="margin-left: 21pt">五、协议终止</p>
              <p class="MsoNormal" style="text-indent: 28pt">
                （一）本协议约定事项已完成。
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                （二）甲乙双方一致决定终止。
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                （三）政府主管部门决定终止。
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                （四）乙方未按协议约定使用资助资金或者有其他违反协议情形的，应按照甲方要求限期整改，限期内不能达到要求或严重违反协议规定的，甲方有权解除资助协议，追偿资助资金。
              </p>
              <p class="MsoNormal" style="text-indent: 27.4pt">
                六、争议解决和适用法律
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                （一）甲乙双方在协议履行过程中发生任何争议，应首先友好协商解决。协商不成时，任何一方均可向北京仲裁委员会提起仲裁，通过仲裁程序解决，且仲裁裁决为终局裁决。
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                （二）本协议的订立、履行和解释及争议的解决均适用中国法律。
              </p>
              <p class="MsoNormal" style="text-indent: 27.4pt">
                七、<a
                  href="http://zhidao.baidu.com/search?word=%E9%99%84%E5%88%99&amp;fr=qb_search_exp&amp;ie=utf8"
                  >协议修改</a
                >和补充
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                本协议修改和补充必须由甲乙双方协商一致，以书面补充协议形式确定，经甲乙双方签字、盖章后与本协议具有同等法律效力。
              </p>
              <p class="MsoNormal" style="text-indent: 27.4pt">八、附则</p>
              <p class="MsoNormal" style="text-indent: 28pt">
                本协议以中文签署，自甲乙双方签字、盖章之日起生效；一式两份，甲乙双方各执一份，具有同等法律效力。
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">&nbsp;</p>
              <p class="MsoNormal" style="text-indent: 28pt">
                （以下无正文，为签署栏及附件）
              </p>
              <p class="15">&nbsp;</p>
              <p class="15">&nbsp;</p>
              <p class="MsoNormal" style="text-indent: 28pt">
                <span>甲方：（盖章）</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>乙方</span>:（盖章）
              </p>
              <p class="MsoNormal">&nbsp;</p>
              <p class="15">&nbsp;</p>
              <p class="15">&nbsp;</p>
              <p class="MsoNormal" style="text-indent: 28pt">
                <span>法定代表人或</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                  >法定代表人或</span
                >
              </p>
              <p class="MsoNormal" style="text-indent: 28pt">
                <span>授权代表：（签字）</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                  >授权代表：（签字）</span
                >
              </p>
              <p class="MsoNormal">&nbsp;</p>
              <p class="MsoNormal" style="text-indent: 28pt">
                <span>年</span> &nbsp;&nbsp;<span>月</span> &nbsp;&nbsp;<span
                  >日</span
                >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                  >年</span
                >
                &nbsp;&nbsp;<span>月</span> &nbsp;&nbsp;<span>日</span>
              </p>
            </div>
            <br />
            <p class="MsoNormal" style="text-indent: 28pt">
              <b><span>附件</span>:</b><b></b>
            </p>
            <p class="MsoNormal" align="center" style="text-align: center">
              <b>资助项目专项绩效表</b>
            </p>
            <div align="center">
              <table
                class="MsoNormalTable"
                border="1"
                cellspacing="0"
                style="border: none"
              >
                <tbody>
                  <tr>
                    <td
                      width="204"
                      valign="center"
                      colspan="3"
                      style="border: 1pt solid windowtext"
                    >
                      <p
                        class="MsoNormal"
                        align="center"
                        style="text-align: center"
                      >
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >项目名称</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                    </td>
                    <td
                      width="505"
                      valign="center"
                      colspan="2"
                      style="border: 1pt solid windowtext"
                    >
                      <p class="MsoNormal">
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >全国足球特色幼儿园示范园（试点）项目</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="204"
                      valign="center"
                      colspan="3"
                      style="border: 1pt solid windowtext"
                    >
                      <p
                        class="MsoNormal"
                        align="center"
                        style="text-align: center"
                      >
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >项目资金</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                    </td>
                    <td
                      width="505"
                      valign="center"
                      colspan="2"
                      style="border: 1pt solid windowtext"
                    >
                      <p class="MsoNormal">
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >20000.00</span
                        ><span style="font-family: 仿宋; font-size: 12pt"
                          >元（人民币贰万元整）</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="65"
                      valign="center"
                      rowspan="7"
                      style="border: 1pt solid windowtext"
                    >
                      <p
                        class="MsoNormal"
                        align="center"
                        style="text-align: center"
                      >
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >年</span
                        ><span style="font-family: 仿宋; font-size: 12pt"
                          ><br /></span
                        ><span style="font-family: 仿宋; font-size: 12pt"
                          >度</span
                        ><span style="font-family: 仿宋; font-size: 12pt"
                          ><br /></span
                        ><span style="font-family: 仿宋; font-size: 12pt"
                          >总</span
                        ><span style="font-family: 仿宋; font-size: 12pt"
                          ><br /></span
                        ><span style="font-family: 仿宋; font-size: 12pt"
                          >体</span
                        ><span style="font-family: 仿宋; font-size: 12pt"
                          ><br /></span
                        ><span style="font-family: 仿宋; font-size: 12pt"
                          >目</span
                        ><span style="font-family: 仿宋; font-size: 12pt"
                          ><br /></span
                        ><span style="font-family: 仿宋; font-size: 12pt"
                          >标</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                    </td>
                    <td
                      width="644"
                      valign="center"
                      colspan="4"
                      rowspan="7"
                      style="border: 1pt solid windowtext"
                    >
                      <p class="MsoNormal" style="text-indent: 18pt">
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >全面贯彻落实全国教育大会精神，牢固树立健康第一的教育理念，帮助幼儿在体育锻炼中享受乐趣、增强体质、健全人格、锤炼意志。</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                      <p class="MsoNormal" style="text-indent: 18pt">
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >以《全国足球特色幼儿园游戏活动指南》为纲，贯彻校园足球八大体系指导方针，在幼儿足球领域贯彻“教会、勤练、常赛”的先进理念，在全国足球特色幼儿园系统平台开展幼儿足球教学活动，详实记录并反馈相应数据。</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                    </td>
                  </tr>
                  <tr></tr>
                  <tr></tr>
                  <tr></tr>
                  <tr></tr>
                  <tr></tr>
                  <tr></tr>
                  <tr>
                    <td
                      width="65"
                      valign="center"
                      rowspan="11"
                      style="border: 1pt solid windowtext"
                    >
                      <p
                        class="MsoNormal"
                        align="center"
                        style="text-align: center"
                      >
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >绩效指标</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                    </td>
                    <td
                      width="62"
                      valign="center"
                      rowspan="2"
                      style="border: 1pt solid windowtext"
                    >
                      <p
                        class="MsoNormal"
                        align="center"
                        style="text-align: center"
                      >
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >一级</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                      <p
                        class="MsoNormal"
                        align="center"
                        style="text-align: center"
                      >
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >指标</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                    </td>
                    <td
                      width="76"
                      valign="center"
                      rowspan="2"
                      style="border: 1pt solid windowtext"
                    >
                      <p
                        class="MsoNormal"
                        align="center"
                        style="text-align: center"
                      >
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >二级</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                      <p
                        class="MsoNormal"
                        align="center"
                        style="text-align: center"
                      >
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >指标</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                    </td>
                    <td
                      width="348"
                      valign="center"
                      rowspan="2"
                      style="border: 1pt solid windowtext"
                    >
                      <p
                        class="MsoNormal"
                        align="center"
                        style="text-align: center"
                      >
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >三级指标</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                    </td>
                    <td
                      width="156"
                      valign="center"
                      rowspan="2"
                      style="border: 1pt solid windowtext"
                    >
                      <p
                        class="MsoNormal"
                        align="center"
                        style="text-align: center"
                      >
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >指标值</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                    </td>
                  </tr>
                  <tr></tr>
                  <tr>
                    <td
                      width="62"
                      valign="center"
                      rowspan="6"
                      style="border: 1pt solid windowtext"
                    >
                      <p
                        class="MsoNormal"
                        align="center"
                        style="text-align: center"
                      >
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >产出指标</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                    </td>
                    <td
                      width="76"
                      valign="center"
                      rowspan="6"
                      style="border: 1pt solid windowtext"
                    >
                      <p
                        class="MsoNormal"
                        align="center"
                        style="text-align: center"
                      >
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >数量指标</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                    </td>
                    <td
                      width="348"
                      valign="center"
                      style="border: 1pt solid windowtext"
                    >
                      <p
                        class="MsoNormal"
                        style="text-indent: 24pt; vertical-align: middle"
                      >
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >幼儿园基础信息管理体系（KMI）</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                    </td>
                    <td
                      width="156"
                      valign="center"
                      style="border: 1pt solid windowtext"
                    >
                      <p class="MsoNormal" style="vertical-align: middle">
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >录入≥200幼儿</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="348"
                      valign="center"
                      style="border: 1pt solid windowtext"
                    >
                      <p
                        class="MsoNormal"
                        style="text-indent: 24pt; vertical-align: middle"
                      >
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >幼儿足球游戏课程体系（LGA）</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                    </td>
                    <td
                      width="156"
                      valign="center"
                      style="border: 1pt solid windowtext"
                    >
                      <p class="MsoNormal" style="vertical-align: middle">
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >≥16节/班</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="348"
                      valign="center"
                      style="border: 1pt solid windowtext"
                    >
                      <p
                        class="MsoNormal"
                        style="text-indent: 24pt; vertical-align: middle"
                      >
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >幼儿足球游戏测试体系（PDA）</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                    </td>
                    <td
                      width="156"
                      valign="center"
                      style="border: 1pt solid windowtext"
                    >
                      <p class="MsoNormal" style="vertical-align: middle">
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >≥2次</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                      <!-- <p class="MsoNormal" style="vertical-align: middle">
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >≥200人/次</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p> -->
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="348"
                      valign="center"
                      style="border: 1pt solid windowtext"
                    >
                      <p
                        class="MsoNormal"
                        style="text-indent: 24pt; vertical-align: middle"
                      >
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >幼儿足球运动评估体系(PES) </span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                    </td>
                    <td
                      width="156"
                      valign="center"
                      style="border: 1pt solid windowtext"
                    >
                      <p class="MsoNormal" style="vertical-align: middle">
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >≥2次</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                      <!-- <p class="MsoNormal" style="vertical-align: middle">
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >≥200份/次</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p> -->
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="348"
                      valign="center"
                      style="border: 1pt solid windowtext"
                    >
                      <p
                        class="MsoNormal"
                        align="center"
                        style="text-align: center; vertical-align: middle"
                      >
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >幼儿足球教师培训体系(TCP)</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                    </td>
                    <td
                      width="156"
                      valign="center"
                      style="border: 1pt solid windowtext"
                    >
                      <p class="MsoNormal" style="vertical-align: middle">
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >教师参与培训</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="348"
                      valign="center"
                      style="border: 1pt solid windowtext"
                    >
                      <p
                        class="MsoNormal"
                        style="text-indent: 24pt; vertical-align: middle"
                      >
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >小小运动会(KSFA)</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                    </td>
                    <td
                      width="156"
                      valign="center"
                      style="border: 1pt solid windowtext"
                    >
                      <p class="MsoNormal" style="vertical-align: middle">
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >≥1次</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                    </td>
                  </tr>
                  <!-- <tr>
                    <td
                      width="62"
                      valign="center"
                      style="border: 1pt solid windowtext"
                    >
                      <p
                        class="MsoNormal"
                        align="center"
                        style="text-align: center"
                      >
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >&nbsp;</span
                        >
                      </p>
                    </td>
                    <td
                      width="76"
                      valign="center"
                      style="border: 1pt solid windowtext"
                    >
                      <p
                        class="MsoNormal"
                        align="center"
                        style="text-align: center"
                      >
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >&nbsp;</span
                        >
                      </p>
                    </td>
                    <td
                      width="348"
                      valign="center"
                      style="border: 1pt solid windowtext"
                    >
                      <p class="MsoNormal" style="vertical-align: middle">
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >幼儿足球全国综合量化评估体系(KQA）</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                    </td>
                    <td
                      width="156"
                      valign="center"
                      style="border: 1pt solid windowtext"
                    >
                      <p class="MsoNormal" style="vertical-align: middle">
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >≥10000积分</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                    </td>
                  </tr> -->

                  <tr>
                    <td
                      width="62"
                      valign="center"
                      style="border: 1pt solid windowtext"
                    >
                      <p
                        class="MsoNormal"
                        align="center"
                        style="text-align: center"
                      >
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >效益指标</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                    </td>
                    <td
                      width="76"
                      valign="center"
                      style="border: 1pt solid windowtext"
                    >
                      <p
                        class="MsoNormal"
                        align="center"
                        style="text-align: center"
                      >
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >社会效益指标</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                    </td>
                    <td
                      width="348"
                      valign="center"
                      style="border: 1pt solid windowtext"
                    >
                      <p
                        class="MsoNormal"
                        style="text-indent: 24pt; vertical-align: middle"
                      >
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >通过项目的实施可以有效统一思想、明确学校体育包括幼儿园的体育改革发展的总体思路，从而在全国树立标杆，带动全国幼儿体育的整体发展。</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                    </td>
                    <td
                      width="156"
                      valign="center"
                      style="border: 1pt solid windowtext"
                    >
                      <p class="MsoNormal" style="vertical-align: middle">
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >较显著</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="62"
                      valign="center"
                      style="border: 1pt solid windowtext"
                    >
                      <p
                        class="MsoNormal"
                        align="center"
                        style="text-align: center"
                      >
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >满意度指标</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                    </td>
                    <td
                      width="76"
                      valign="center"
                      style="border: 1pt solid windowtext"
                    >
                      <p
                        class="MsoNormal"
                        align="center"
                        style="text-align: center"
                      >
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >服务对象满意度指标</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                    </td>
                    <td
                      width="348"
                      valign="center"
                      style="border: 1pt solid windowtext"
                    >
                      <p
                        class="MsoNormal"
                        style="text-indent: 24pt; vertical-align: middle"
                      >
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >活动参与人员满意度</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                    </td>
                    <td
                      width="156"
                      valign="center"
                      style="border: 1pt solid windowtext"
                    >
                      <p class="MsoNormal" style="vertical-align: middle">
                        <span style="font-family: 仿宋; font-size: 12pt"
                          >≥85%</span
                        ><span
                          style="font-family: 仿宋; font-size: 12pt"
                        ></span>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- 协议结束 -->
          </div>
        </div>
      </div>
      <!-- <a class="btndis" id="time"></a> -->
      <a class="btnsub tab" @click="isActiveIndex = 7">返回</a>
    </div>
    <!-- 完成申报 -->
    <div class="step8" v-show="isActiveIndex === 6">
      <div class="publicity" style="width: 800px">
        您的线上申报已成功，正式进入签约及收付款环节，请您耐心等待系统平台客服老师与您联系，如有疑问请拨打客服咨询热线400-831-9799。
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="declaInstruction"
      width="30%"
      custom-class="declaIns"
      :show-close="false"
      :close-on-click-modal="false"
      >
      <div slot="title" class="declaIns-header">
        申报须知
      </div>
      <div class="declaIns-info">
        <p class="declaIns-info-tit">
          【示范园申报】重要须知
        </p>
        <p>感谢您使用“全国足球特色幼儿园示范园试点项目”申报系统！</p>
        <p>确认申报前，请您务必仔细阅读本须知（<span>尤其是红色加粗字体</span>）自您开 始填报信息，本系统平台即已在每个环节申报工作中开始投入大量的人力、 物力，
        <span>之前环节可退出、可放弃，但本“确认申报”环节以后不得退出、 不得放弃。</span></p>
        <p>您点击“同意并继续”后，本须知即构成具有约束力的法律文件。无论 您事实上是否认真阅读过了本须知，只要您点击“同意并申报”，您的行为 表示您仔细阅读并同意本须知。</p>
        <p>不同意的，可以点击“取消并退出”，视为您并未申报。<span>否则，本环节 您确认提交申报后又退出或放弃的，所造成的申报各环节相应的损失，本系统保留向您追索的权利。</span></p>
        <p style="margin-top:28px;">申报中，您有任何疑问请拨申报咨询电话：<b>400-831-9799</b></p>
        <p style="text-align:right;margin-top:0.28rem;">全国足球特色幼儿园系统平台</p>
      </div>
      <div class="declaIns-footer">
        <p class="declaIns-submit" :class="declaInsNum == 0 ? 'submitActive' : ''" type="primary" @click="confirmDeclaIns"><span v-if="declaInsNum">({{declaInsNum}}s)</span>同意并申报</p>
        <p class="declaIns-cancel" @click="logoutDeclare()">取消并退出</p>
      </div>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="reminderStatus"
      width="30%"
      custom-class="declaIns"
      :show-close="false"
      :close-on-click-modal="false"
      >
      <div slot="title" class="declaIns-header">
      温馨提示
      </div>
      <div class="declaIns-info reminder-p">
        <p class="declaIns-info-tit">
         【示范园申报】温馨提示
        </p>
        <p class="reminder-tit">欢迎您进入全国足球特色幼儿园示范园试点项目申报系统，在申报前有几 点信息需认真阅读：</p>
        <p>1.入选园所采用自愿申报原则，请您确保如实填写申报信息。</p>
        <p>2.示范园试点园所确认申报后，会由工作人员与您联系，进入签约流程。</p>
        <p>3.签约时需由贵园所开具增值税普通发票等有效票据，票据类型在申报环节中已 列明，请您根据实际情况选择。</p>
        <p>4.签约资料回寄后，经中国足球发展基金会审核通过方可进行资助。</p>
        <p>5.此资助金是为示范园开展试点教学活动的费用，实行专款专用，请贵园所 于三个工作日内将资助金支付至指定账户。</p>
        <p style="margin-top:0.28rem;">申报中，您有任何疑问请拨申报咨询电话：<b>400-831-9799</b></p>
      </div>
      <div class="declaIns-footer">
        <p class="declaIns-submit"  :class="declaInsNum == 0 ? 'submitActive' : ''" type="primary" @click="confirmReminder"><span v-if="declaInsNum">({{declaInsNum}}s)</span>已知晓继续申报</p>
        <p class="declaIns-cancel" @click="logoutDeclare()">退出申报</p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "integral",
  components: {},
  data() {
    return {
      declaInstruction:false, //申报须知弹窗
      reminderStatus:false,  //温馨提示
      declaInsNum:15, //申报须知倒计时
      checked: false,
      imageUrl: "",
      image: "",
      parkinfo: JSON.parse(localStorage.getItem("parkinfo")),
      isActiveIndex: 1,
      flag: false,
      // 手机验证
      phone: "",
      code: "",
      codeTime: 0,
      status: false, //实名认证
      //发票
      invoiceType: {
        type: 1,
        label: "普票",
      },
      invoiceCurrentType:'普票',
      collectionList: {
        spSchoolName:this.$store.state.schoolInfo.atteSchool.schoolName,  //园所名称
        companyName: "", //单位名称
        bankAccount: "", //银行账号
        bankName: "", //开户行
        address: "", //地址
        contacts: "", //法人
        idCard: "", //身份证号
        schoolId: this.$store.state.userInfo.schoolId,
      },
      // 统一信用代码
      invoicePerfect: {
        spEmail: "",
        creditCode: "",
      },
      // 发票列表
      invoiceList: [],
      invoiceTypeInput: {
        type: 3,
        label: "",
      },

      invoiceCheck: false, // 是否已知悉条件自愿申报
      timer:null,
    };
  },
  mounted() {
    // 查询是否申请过示范园
    this.getScollDta(); // 获取未通过学校信息数据
    this.getUser();
    this.api.unit.getPhone(this.$store.state.userInfo.userId).then((res) => {
      this.phone = res.data.mobile.slice(1);
    });
    this.getIncoiceList();
  },

  methods: {
    //手机号数字校验
    inputChanges() {
      this.phone = this.phone.replace(/[^\d]/g, "");
    },
    //验证码数字校验
    inputChange() {
      this.code = this.code.replace(/[^\d]/g, "");
    },
    // 统一信用代码
    handInvoice() {
      if (!this.common.validateLength(this.invoicePerfect.spEmail, 25)) {
        return this.$notice({
          message: "请输入邮箱",
        }).isShow();
      } else {
        if (!this.common.isEmail(this.invoicePerfect.spEmail)) {
          return this.$notice({
            message: "请输入正确邮箱",
          }).isShow();
        }
      }
      if (!this.invoicePerfect.creditCode) {
        return this.$notice({
          message: "请输入统一社会信用代码",
        }).isShow();
      }
      // if (!this.common.CheckSocialCreditCode(this.invoicePerfect.creditCode)) {
      //   return this.$notice({
      //     message: "请输入正确统一社会信用代码",
      //   }).isShow();
      // }
      if (this.invoicePerfect.spEmail && this.invoicePerfect.creditCode) {
        var data = {
          spEmail: this.invoicePerfect.spEmail,
          creditCode: this.invoicePerfect.creditCode,
          id: this.$store.state.userInfo.schoolId,
        };
        this.api.park.confirmInfo(data).then((res) => {
          if (res.flag) {
            this.isActiveIndex = 7;
          }
        });
      }
    },
    // 确认申报
    handDeclare() {
      if (!this.checked) {
        this.$notice({
          message: "请同意以上条件，自愿申报",
        }).isShow();
        return;
      } else {
        this.declaInstruction = true;
        this.declaInsNum = 15;
        this.countDown();
      }
    },
    //开始倒计时
    countDown(){
      clearInterval(this.timer)
      this.timer = setInterval(()=>{
        if(this.declaInsNum == 0){
          clearInterval(this.timer)
        }else{
          this.declaInsNum--;
        }
      },1000)
    },
    // 上传
    handleAvatarSuccess(res, file) {
      console.log("图片", res.data.filename);
      console.log("图片1");
      this.image = res.data.filename;
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    handCollection() {
      if (!this.collectionList.spSchoolName)
        return this.$notice({
          message: "请完善信息后再点下一步",
        }).isShow();
      if (!this.common.validateLength(this.collectionList.spSchoolName, 50))
        //开户行
        return this.$notice({
          message: "请输入正确园所名称",
        }).isShow();
      if (!this.collectionList.address)
        return this.$notice({
          message: "请完善信息后再点下一步",
        }).isShow();
      if (!this.collectionList.contacts)
        //法定代表人
        return this.$notice({
          message: "请完善信息后再点下一步",
        }).isShow();
      if (!this.common.textNumberCheck(this.collectionList.contacts, 25))
        //法定代表人
        return this.$notice({
          message: "请输入正确法定代表人姓名",
        }).isShow();
      if (!this.collectionList.idCard)
        //法人身份证
        return this.$notice({
          message: "请完善信息后再点下一步",
        }).isShow();
      if (!this.common.validateIdCard(this.collectionList.idCard))
        //法人身份证
        return this.$notice({
          message: "请输入正确法人身份证",
        }).isShow();
      if (!this.collectionList.companyName)
        //开户单位名称
        return this.$notice({
          message: "请完善信息后再点下一步",
        }).isShow();
      if (!this.common.validateLength(this.collectionList.companyName, 50))
        //开户单位名称
        return this.$notice({
          message: "请输入正确开户单位名称",
        }).isShow();
      if (!this.collectionList.bankName)
        //开户行
        return this.$notice({
          message: "请完善信息后再点下一步",
        }).isShow();
      if (!this.common.validateLength(this.collectionList.bankName, 50))
        //开户行
        return this.$notice({
          message: "请输入正确开户行",
        }).isShow();
      if (!this.collectionList.bankAccount)
        //银行账号
        return this.$notice({
          message: "请完善信息后再点下一步",
        }).isShow();
      if (!this.common.validateLength(this.collectionList.bankAccount, 25))
        //银行账号
        return this.$notice({
          message: "请输入正确银行账号",
        }).isShow();
      this.collectionList.bankAccount = this.collectionList.bankAccount.replace(
        /\s|-|_/g,
        ""
      );
      this.api.park.perfectInfo(this.collectionList).then((res) => {
        if (res.flag) {
          this.isActiveIndex = 5;
        }
      });
    },
    // 获取发票类型
    getIncoiceList() {
      this.api.park.all().then((res) => {
        this.invoiceList = res.data;
      });
    },
    // 发送验证码
    hanlePhoneCode() {
      if (!this.phone) {
        this.$notice({
          message: "请完善个人信息后再申请示范园",
        }).isShow();
        return;
      }
      this.api.park.verifymobileSend({ phone: this.phone }).then((res) => {
        if (res.flag) {
          this.$notice({
            type: "success",
            message: "验证码发送成功",
          }).isShow();
          this.codeTime = 180;
          let timer = setInterval(() => {
            this.codeTime = this.codeTime - 1;
            if (!this.codeTime) {
              clearInterval(timer);
            }
          }, 1000);
        } else {
          this.$notice({
            message: res.message,
          }).isShow();
        }
      });
    },
    // 手机号提交验证
    submitPhone() {
      console.log(1231231);
      this.api.user
        .verifymobileCode({
          phone: this.phone,
          code: this.code,
        })
        .then((res) => {
          if (res.flag) {
            this.isActiveIndex = 2;
            this.reminderStatus = true;
            this.declaInsNum = 15;
            this.countDown();
          } else {
            this.$message.error(res.message);
          }
        });
    },
    // 发票切换1
    handleInvoiceType(val, str) {
      console.log(val);
      console.log(str);
      this.invoiceType.type = val;
      this.invoiceType.label = str;
      this.invoiceTypeInput.label = "";
      // this.flag = false;
      this.imageUrl = "";
      this.image = "";
      this.invoiceCurrentType = str;
    },
    // handleInvoiceInputType(item) {
    //   if (item.id !== 1 && item.id !== 2) {
    //     this.flag = true;
    //     this.imageUrl = "";
    //     this.image = "";
    //   } else {
    //     this.flag = false;
    //   }
    //   this.invoiceTypeInput.label = item.name;
    // },
    // 发票提交
    invoiceSubmit() {
      /* console.log("this.imageUrl",this.imageUrl);
      console.log("this.image",this.image); */
      if (!this.status) {
        this.$notice({
          message: "请完成实名认证",
        }).isShow();
        return;
      }
      if (!this.image) {
        console.log(this.flag);
        this.$notice({
          message: "请上传您园所开具过的对应票据",
        }).isShow();
        return;
      }
      if (!this.invoiceCheck) {
        this.$notice({
          message: "请同意以上条件，自愿申报",
        }).isShow();
        return;
      } else {
        this.isActiveIndex = 4;
      }
      // 判断示范园状态 todo
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
        invoiceName: this.invoiceTypeInput.label
          ? this.invoiceTypeInput.label
          : this.invoiceType.label,
        invoiceType: this.invoiceTypeInput.label
          ? this.invoiceTypeInput.type
          : this.invoiceType.type,
        imgurl: this.image,
      };
      this.api.park.invoice(data).then((res) => {
        console.log(res);
      });
    },
    // 获取未通过学校信息数据
    getScollDta() {
      let id = this.$store.state.userInfo.schoolId;
      this.api.user.schollDataCenter(id).then((res) => {
        console.log("1234", res);
        if (res.data.confirmStatus == -1) {
          let collectionList = {
            spSchoolName: res.data.spSchoolName || this.$store.state.schoolInfo.atteSchool.schoolName,
            companyName: res.data.companyName,
            bankAccount: res.data.bankAccount,
            bankName: res.data.bankName,
            address: res.data.paddress,
            contacts: res.data.spContacter,
            idCard: res.data.idCard,
            schoolId: res.data.id,
          };

          let obj = {
            spEmail: res.data.spEmail,
            creditCode: res.data.creditCode,
          };
          //发票
          let inType = {
            type: res.data.sfyinvoiceType,
            label: res.data.sfyinvoiceName,
          };
          if (res.data.sfyinvoiceType == 3) {
            let invoiceTypeInput = {
              type: res.data.sfyinvoiceType,
              label: res.data.sfyinvoiceName,
            };
            this.invoiceTypeInput = invoiceTypeInput;
          }
          this.invoiceCurrentType = res.data.sfyinvoiceName;
          if (res.data.sfyinvoiceURL) {
            this.flag = true;
            this.imageUrl = this.constant.URL + "/" + res.data.sfyinvoiceURL;
            this.image = res.data.sfyinvoiceURL;
          }

          this.invoiceType = inType;
          this.invoicePerfect = obj;
          this.collectionList = collectionList;
          this.invoiceCheck = true;
          this.checked = true;
        }
      });
    },
    getUser() {
      this.api.user.findUserCenter().then((res) => {
        console.log("园信息", res.data.atteSchool.level);
        JSON.stringify(localStorage.setItem("yuan", res.data.atteSchool.level));
        // let test = 2;
        // if (test == 2) {
        //   this.isActiveIndex = 6;
        //   return;
        // }
        if (!res.data.atteSchool.whiteList) {
          this.$notice({
            message: "您的园所暂不能申请示范园",
          }).isShow();
          this.$router.go(-1);
        } else {
          if (res.data.atteSchool.whiteList == 0) {
            this.$notice({
              message: "您的园所暂不能申请示范园",
            }).isShow();
            this.$router.go(-1);
          }
        }
        if (res.data.schoolStatus === "88") {
          //完善信息
          this.status = res.data.schoolStatus === "88" ? true : false;
        }
        // else {
        //   this.$notice({
        //     message :"请完善信息"
        //   }).isShow()
        //   // this.$router.push('/admin/schoolHome')
        // }
        // console.log(res);
        if (res.data.atteSchool.level === 6 || res.data.declare === 1) {
          this.isActiveIndex = 6;
        }
      });
    },
    logoutDeclare(){
      this.reminderStatus = false;
      this.declaInstruction = false;
      //清除定时器
      clearInterval(this.timer);
      this.$router.push({
        path: "/home",
      });
    },
    confirmReminder(){  //温馨提示确认
      if(this.declaInsNum) return;
      this.reminderStatus = false;
      clearInterval(this.timer);
    },
    confirmDeclaIns(){  //申报须知确认
      if(this.declaInsNum) return;
      this.declaInstruction = false;
      clearInterval(this.timer);
      var data = {
        schoolId: this.$store.state.userInfo.schoolId,
        role: "2",
      };
      this.api.park.saveContract(data).then((res) => {
        if (!res.code === 20000) {
          this.$notice({
            message: this.res.message,
          }).isShow();
        }
      });
      this.isActiveIndex = 6;
    }
  },
};
</script>

<style lang="scss" scoped>
.park_page {
  .invoice_block {
    width: 100%;
    margin: 0 auto;
    .check_item {
      // width: 100%;
      padding: 0 20px;
      height: 42px;
      background: #BA3037;
      border-radius: 4px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      margin-bottom: 19px;
      @include flex(row, space-between, center);
      .check {
        width: 29px;
        height: 29px;
        border-radius: 50%;
        background: #fff;
        margin: 0 32px 0 26px;
        @include bg("../../assets/img/park/active.png");
      }
    }
    .invoice_type {
      font-size: 27px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      margin-top: 22px;
      .right_item {
        margin-bottom: 25px;
        @include flex(row, flex-start, center);
        span {
          width: 24px;
          height: 24px;
          border: 1px solid #a5a5a5;
          border-radius: 50%;
          margin-left: 5px;
        }
        .invoice_span_title {
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 300;
          color: #d09540;
          border: none;
          white-space: nowrap;
        }
      }
      .invoice_title{
        margin-bottom:20px;
        font-size: 18px;
        font-weight: bold;
        color: #333333;
      }
      .bill_list{
        @include flex(row, space-between, center);
        margin-bottom:15px;
        .bill_item{
          margin:0;
          padding:0 15px;
          width: 420px;
          height: 45px;
          border: 1px solid #BA3037;
          border-radius: 19px;
          font-size:16px;
        }
        .checked{ 
          background:#BA3037 url('../../assets/img/park/Icon_Lx_choose.png') no-repeat 410px center;
          background-size: 28px 28px;
          color:#FFFFFF;
        }
      }
      
    }
    .invoice_list {
      @include flex(row, flex-start, center);
      flex-wrap: wrap;
      border: 1px solid #a5a5a5;
      border-radius: 20px;
      margin: 11px auto 27px;
      padding: 32px 46px;
      .invoice_item {
        display: table-cell !important;
        vertical-align: middle !important;
        width: 380px;
        height: 70px;
        background: #a5a5a5;
        // border-radius: 20px;
        padding: 13px 18px;
        margin-right: 65px;
        font-size: 27px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        margin-bottom: 10px;
        color: #ffffff;
      }
    }
    .invoice_check {
      @include flex(row, flex-start, center);
      font-size: 27px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      span {
        width: 24px;
        height: 24px;
        border: 1px solid #a5a5a5;
        border-radius: 50%;
        margin: 0 12px 0 83px;
      }
    }
    .invoice_submit_btns {
      margin: 94px auto 20px;
      @include flex();
      .invoice_submit {
        width: 156px;
        height: 50px;
        margin-right: 80px;
        background: #cc1a30;
        border-radius: 20px;
        font-size: 27px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        @include flex();
      }
      .invoice_submit:last-child {
        margin-right: 0;
      }
      .invoice_submit:hover {
        opacity: 0.7;
      }
    }
    .active {
      background-color: #cc1a30 !important;
      border: none;
    }
  }

  .sports_banner {
    width: 100%;

    img {
      width: 100%;
    }
  }

  .stepbar {
    display: table;
    margin: 0.7rem auto;

    ul {
      display: flex;
    }

    li {
      display: flex;
      align-items: center;
      font-size: 0.3rem;
      font-weight: bold;
      color: #505051;
      position: relative;
      line-height: 0.32rem;

      .circle {
        border-width: 0.03rem;
        border-color: rgb(191, 191, 191);
        border-style: solid;
        border-radius: 50%;
        background-color: rgb(255, 255, 255);
        width: 0.38rem;
        height: 0.38rem;
        margin-right: 0.12rem;
        box-sizing: border-box;
      }

      .arr {
        width: 0.22rem;
        height: auto;
        margin-left: 0.2rem;
        margin-right: 0.2rem;
      }
    }

    li.active .circle {
      background: url("../../assets/img/declaration/circle.png") center
        no-repeat;
      background-size: cover;
      border: 0;
    }
  }

  .stepbar {
    display: table;
    margin: 0.7rem auto;
  }

  .stepbar ul {
    display: flex;
  }

  .stepbar li {
    display: flex;
    align-items: center;
    font-size: 0.3rem;
    font-weight: bold;
    color: #505051;
    position: relative;
    line-height: 0.32rem;
  }

  .stepbar li .circle {
    border-width: 0.03rem;
    border-color: rgb(191, 191, 191);
    border-style: solid;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    width: 0.38rem;
    height: 0.38rem;
    margin-right: 0.12rem;
    box-sizing: border-box;
  }

  .stepbar li .arr {
    width: 0.22rem;
    height: auto;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }

  .stepbar li.active .circle {
    background: url("../../assets/img/declaration/circle.png") center no-repeat;
    background-size: cover;
    border: 0;
  }

  .steptit {
    margin-bottom: 0.5rem;
    text-align: center;
    font-size: 0.4rem;
    font-weight: bold;
    color: #000;
  }

  .btnsub {
    display: block;
    border: 0;
    border-radius: 0.1rem;
    background-color: rgb(204, 26, 48);
    width: 4rem;
    height: 0.7rem;
    line-height: 0.7rem;
    margin: 0.5rem auto 1rem auto;
    font-size: 0.3rem;
    font-weight: bold;
    text-align: center;
    color: #fff;
  }

  .btnsub:hover {
    color: #fff;
    opacity: 0.7;
  }

  .btndis {
    display: block;
    border: 0;
    border-radius: 0.1rem;
    background-color: #919191;
    width: 4rem;
    height: 0.7rem;
    line-height: 0.7rem;
    margin: 0.5rem auto 1rem auto;
    font-size: 0.3rem;
    font-weight: bold;
    text-align: center;
    color: #fff;
  }

  #captcha {
    border: 0;
    font-size: 0.24rem;
    color: rgb(255, 255, 255);
    line-height: 0.5rem;
    text-align: center;
    border-radius: 0.06rem;
    background-color: rgb(40, 74, 160);
    width: 1.7rem;
    height: 0.5rem;
    margin-left: 0.3rem;
    cursor: pointer;
  }

  #captcha.disabled {
    background-color: #919191;
  }

  .w1200 {
    width: 1478px;
    margin: 0 auto;
  }

  .describe {
    font-size: 0.28rem;
    color: #505050;
    line-height: 0.44rem;
  }

  .checkphone .layui-form-label {
    width: 3.2rem;
    padding: 0;
    white-space: nowrap;
    font-size: 0.3rem;
    line-height: 0.54rem;
    color: #505051;
  }

  .checkphone .layui-form-item {
    display: table;
    width: 75%;
    margin: 0 auto 0.5rem;
  }

  .checkphone .layui-input-block {
    margin: 0;
    float: left;
    display: flex;
  }

  .checkphone .layui-input-block .layui-input {
    border-width: 1px;
    border-color: rgb(210, 210, 210);
    border-style: solid;
    width: 5.28rem;
    height: 0.54rem;
    font-size: 0.3rem;
  }

  .receivepay {
    font-size: 0.28rem;
    line-height: 0.28rem;
    color: #505050;
  }

  .receivepay .tit {
    font-size: 0.32rem;
    line-height: 0.32rem;
    font-weight: bold;
  }

  .receivepay .desc {
    margin-bottom: 0.3rem;
    line-height: 0.4rem;
  }

  .receivepay .desc p {
    margin-top: 0.3rem;
  }

  .receivepay .layui-form-label {
    width: 3.2rem;
    padding: 0;
    white-space: nowrap;
    font-size: 0.28rem;
    line-height: 0.4rem;
    color: #505051;
  }

  .receivepay .labelc {
    width: 2.2rem;
  }

  .receivepay .layui-form-label i {
    color: #ff0000;
    display: inline-block;
    vertical-align: text-top;
    margin-right: 0.1rem;
  }

  .receivepay .layui-form-item {
    display: table;
    margin-bottom: 0.2rem;
    line-height: 0.4rem;
  }

  .receivepay .layui-input-block {
    margin: 0;
    float: left;
    display: flex;
  }

  .receivepay .layui-form-item .tips {
    clear: both;
    font-size: 14px;
    margin-left: 2.2rem;
  }

  .receivepay .layui-input-block .layui-input {
    border-width: 1px;
    border-color: rgb(210, 210, 210);
    border-style: solid;
    width: 5.28rem;
    height: 0.4rem;
    font-size: 0.28rem;
  }

  .receivepay .layui-input-block .sub {
    margin-left: 0.2rem;
    font-size: 0.26rem;
    color: #939393;
  }

  .protocol {
    background-color: rgb(250, 250, 250);
    padding: 0.2rem;
  }

  .protocol .box {
    border-width: 1px;
    border-color: rgb(250, 205, 137);
    border-style: solid;
    padding: 0.5rem;
    color: #505051;
    /* height: 4rem; */
    box-sizing: border-box;
    border-radius: 0.1rem;
    line-height: 0.4rem;
    background-color: #fff;
  }

  .protocol .box .title {
    font-size: 0.32rem;
    line-height: 0.32rem;
    text-align: center;
    margin-bottom: 0.3rem;
  }

  .protocol .box .content {
    font-size: 0.26rem;
    line-height: 0.4rem;
    /* height: 2.8rem;
    box-sizing: border-box;
    overflow: scroll; */
  }

  .pact {
    background-color: rgb(250, 250, 250);
    display: table;
  }

  .pact .choose {
    display: table-cell;
    text-align: center;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }

  .pact .choose .box {
    margin: 0 auto;
    border-width: 1px;
    border-color: rgb(250, 205, 137);
    border-style: solid;
    border-radius: 0.1rem;
    background-color: rgb(255, 255, 255);
    width: 3.48rem;
    height: 3.88rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.3rem;
    -webkit-transition: 0.1s linear;
    transition: 0.1s linear;
  }

  .pact .choose .box:hover {
    box-shadow: 1px 1px 5px rgb(250, 205, 137);
  }

  .pact .choose .box img {
    width: 2.5rem;
    height: auto;
  }

  .pact .choose .name {
    font-size: 0.32rem;
    line-height: 0.4rem;
    font-weight: bold;
    color: #505051;
  }

  .agree {
    margin: 0.4rem auto;
    text-align: center;
  }

  .agree .layui-form-checkbox[lay-skin="primary"] i {
    width: 0.2rem;
    height: 0.2rem;
    line-height: 0.2rem;
    border: 1px solid #d2d2d2;
    border-radius: 0.04rem;
    top: 0.06rem;
  }

  .agree .layui-form-checkbox span,
  .agree .layui-form-checkbox {
    line-height: 0.4rem !important;
    font-size: 0.28rem !important;
    color: #505051 !important;
    width: 10rem;
    white-space: pre-line;
  }

  .agree .layui-form-checkbox[lay-skin="primary"]:hover i {
    border-color: #cc1a30;
  }

  .agree .layui-form-checked[lay-skin="primary"] i {
    border-color: #cc1a30 !important;
    background-color: #cc1a30;
  }

  .publicity {
    height: 0.6rem;
    background: url("../../assets/img/declaration/circle.png") left center
      no-repeat;
    background-size: 0.6rem;
    padding-left: 0.84rem;
    font-size: 0.32rem;
    line-height: 0.6rem;
    color: #505051;
    display: table;
    margin: 0.8rem auto 1.7rem auto;
  }
  .el-icon-check {
    width: 30px;
    border: 1px solid white;
    border-radius: 50px;
    background-color: white;
    margin: 0px 30px;
    color: #888;
  }
  .el-icon-close {
    width: 30px;
    border: 1px solid white;
    border-radius: 50px;
    background-color: white;
    margin: 0px 30px;
    color: #888;
  }
}
.avatar-uploader .el-upload {
  margin-top: 30px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  border: 1px dashed #409eff;
  border-radius: 8px;
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  margin-bottom: 40px;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.bs {
  font-size: 16px;
  color: red;
  padding-bottom: 10px;
}
.infoConfirm-tit{
  font-size: 20px;
  font-weight: bold;
  color: #333333;
}
.infoConfirm-cont{
  color: #333333;
  line-height: 30px;
}
.finished{
  font-size: 18px;
  font-weight: bold;
  color: #FFE08B;
  opacity: 0.9;
}
.infoConfirm-agree{
  // @include flex(row, flex-start, center);
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333333;
  text-align: center;
  span {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url('../../assets/img/park/Icon_Xz_Uncho.png') no-repeat;
    background-size:cover;
    margin-right:10px;
  }
  .active{
    background: url('../../assets/img/park/Icon_Xz_Cho.png') no-repeat;
    background-size:cover;
  }
}
.infoConfirm-submit{
  margin:30px auto 0 auto;
  width:232px;
  height:50px;
  text-align: center;
  line-height:45px;
  background: url('../../assets/img/park/Btn_qrsb_HX.png') no-repeat;
  background-size:cover;
  color:#FFFFFF;
  font-size: 18px;
}

.declaIns{
  .declaIns-header{
    height: 60px;
    line-height:60px;
    color: #FFFFFF;
    text-align:center;
    background: #B93138;
    font-weight: bold;
    font-size: 18px;
    border-radius: 10px 10px 0 0;
  }
  .declaIns-info{
    padding:0 10px;
    color: #333333;
    line-height: 24px;
    font-size:16px;
    > p{
      text-indent: 2em;
      span{
        font-weight:bold;
        color:#B93138;
      }
    }
  }
  .declaIns-info-tit{
    margin-bottom: 30px;
    font-size: 18px;
    font-weight:bold;
    text-align: center;
  }
  .declaIns-footer{
    margin-top:60px;
    text-align: center;
    
    .declaIns-cancel{
      margin-left:44px;
      display:inline-block;
      width: 178px;
      height: 38px;
      border: 1px solid #BA3037;
      font-size: 18px;
      color: #BA3037;
      line-height:38px;
      cursor:pointer;
    }
    .declaIns-submit{
      display:inline-block;
      width: 190px;
      height: 49px;
      background: url('../../assets/img/park/Btn_Bg_agree_UnC.png') no-repeat;
      background-size:cover;
      border:none;
      color:#FFFFFF;
      line-height:48px;
      font-size: 18px;
      cursor:pointer;
    }
    .submitActive{
      background: url('../../assets/img/park/Btn_Bg_agree_U.png') no-repeat;
      background-size:cover;
    }
    .submitActive:hover{
      background: url('../../assets/img/park/Btn_Bg_agree_H.png') no-repeat;
      background-size:cover;
    }
  }
  .reminder-tit{
    font-weight: bold;
  }
  .reminder-p p{
    text-indent:0;
  }
}


</style>
<style lang="scss">
.declaIns{
  border-radius: 10px 10px 0 0 !important;
  .el-dialog__header{
    padding:0;
  }
  .el-dialog__headerbtn .el-dialog__close{
    color:#FFFFFF;
  }
}
</style>
